<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      departments: state => state.departments.items,
    }),
  },
  methods: {
    getEmptyDebrisTypesDepartments () {
      return this.departments.map((department) => ({
        departmentId: department.id,
        privateKwu: false,
        declarationKwu: false,
        kpoKwu: false
      }))
    },
  }
}
</script>
