<template>
  <DialogFormWrapper hide-actions>
    <template #form>
      <v-row>
        <v-col
          cols="12"
          class="d-flex align-start"
        >
          <v-form
            @submit.prevent="fetchDebrisTypes"
            ref="fetchDebrisTypesForm"
            class="w-100"
          >
            <v-text-field
              outlined
              v-model="query"
              placeholder="Wyszukaj po numerze odpadu/kruszywa"
              :rules="[rules.debrisCode]"
              v-mask="'## ## ##'"
              @input="fetchDebrisTypes"
            >
              <template #prepend-inner>
                <Icon
                  name="search"
                  size="small"
                  class="mr-2"
                />
              </template>
            </v-text-field>
          </v-form>
        </v-col>
        <v-col cols="12">
          <v-data-table
            v-model="chosenDebrisType"
            single-select
            item-key="externalBdoId"
            class="results-table mb-2"
            height="300"
            :headers="headers"
            :items="fetchedDebrisTypes"
            :loading="isProcessing"
            :items-per-page="-1"
            hide-default-footer
            fixed-header
          >
            <template #[`item.actions`]="{ item }">
              <v-btn
                color="primary"
                class="mx-2"
                outlined
                x-small
                icon
                :disabled="!item.addable"
                :loading="isProcessing"
                @click="chooseDebrisType(item)"
              >
                <v-icon color="primary">
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-expand-transition>
          <v-col
            cols="12"
            v-if="chosenDebrisType.length"
            class="pb-0"
          >
            <v-form @submit.prevent="addDebrisType">
              <v-row>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="displayName"
                    label="Własna nazwa"
                    outlined
                    placeholder="Wpisz nazwę"
                    hide-details
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="clientDisplayName"
                    label="Nazwa dla klienta"
                    outlined
                    placeholder="Wpisz nazwę widoczną dla klienta"
                    hide-details
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col cols="12">
                  <DebrisTypesDepartments v-model="debrisTypesDepartments" />
                </v-col>

                <v-col
                  cols="8"
                  class="pt-0"
                >
                  <v-checkbox
                    v-model="visibleForDriver"
                    label="Widoczne dla kierowcy"
                    hide-details
                    class="mt-0"
                  />
                </v-col>

                <v-col
                  cols="4"
                  class="pb-0"
                >
                  <v-btn
                    color="primary"
                    block
                    dark
                    :loading="isProcessing"
                    type="submit"
                  >
                    <Icon
                      name="add"
                      size="big"
                      color="#37C87A"
                      class="mr-4"
                    />
                    Dodaj typ {{ isAggregate ? 'kruszywa' : 'odpadu' }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-expand-transition>
      </v-row>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapActions } from 'vuex'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import DebrisTypesDepartments from './Partials/DebrisTypesDepartments'
import api from '../../api/v1'
import rules from '../../utils/validators'
import emptyDebrisTypesDepartments from '../../mixins/emptyDebrisTypesDepartments'
import debounce from 'lodash/debounce'

export default {
  components: {
    DialogFormWrapper,
    DebrisTypesDepartments,
  },
  props: {
    tableName: {
      type: String,
      default: 'debrisTypes'
    }
  },
  mixins: [emptyDebrisTypesDepartments], // getEmptyDebrisTypesDepartments
  data () {
    return {
      rules,
      isProcessing: false,
      fetchedDebrisTypes: [],
      chosenDebrisType: [],
      debrisTypesDepartments: [],
      isAggregate: false,
      visibleForDriver: true,
      displayName: '',
      clientDisplayName: '',
      query: '',
      headers: [
        { text: 'Nazwa', value: 'name' },
        { text: 'Numer', value: 'code', width: 100 },
        { text: '', value: 'actions' }
      ]
    }
  },
  created () {
    this.isAggregate = this.tableName === 'aggregateTypes'
    this.debrisTypesDepartments = this.getEmptyDebrisTypesDepartments()
  },
  methods: {
    ...mapActions({
      createDebrisType: 'debrisTypes/addNewItem',
      getDebrisTypes: function (dispatch) {
        return dispatch(`${this.tableName}/getItems`)
      },
      showSnackbar: 'snackbar/showSnackbar',
    }),
    clearChosenDebrisType () {
      this.chosenDebrisType = []
    },
    chooseDebrisType (item) {
      this.chosenDebrisType = [item]
      this.displayName = item.name
      this.clientDisplayName = item.name
    },
    fetchDebrisTypes: debounce(function () {
      if (this.query && this.$refs.fetchDebrisTypesForm.validate()) {
        this.isProcessing = true
        // todo move call to store module
        api.fetchDebrisTypes({ query: this.query })
          .then((resp) => {
            this.fetchedDebrisTypes = resp.data
            this.clearChosenDebrisType()
          })
          .catch((error) => {
            this.showSnackbar({
              message: error.response.data.errors || ['Nie znaleziono'],
              type: 'error'
            })
          })
          .finally(() => {
            this.isProcessing = false
          })
      } else {
        this.fetchedDebrisTypes = []
        this.clearChosenDebrisType()
      }
    }, 500),
    addDebrisType () {
      this.isProcessing = true
      const params = {
        ...this.chosenDebrisType[0],
        aggregate: this.isAggregate,
        visibleForDriver: this.visibleForDriver,
        displayName: this.displayName,
        clientDisplayName: this.clientDisplayName,
        debrisTypesDepartments: this.debrisTypesDepartments,
      }
      this.createDebrisType({ params })
        .then(() => {
          const debrisTypeIndex = this.fetchedDebrisTypes.findIndex(debrisType => {
            return debrisType.externalBdoId === this.chosenDebrisType[0].externalBdoId
          })
          this.fetchedDebrisTypes[debrisTypeIndex].addable = false
          this.clearChosenDebrisType()
          this.getDebrisTypes()
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}
</script>
